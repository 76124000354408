#insta-icon{
  width: 1.5rem;
  height: 1.5rem;
  padding: auto 1rem;
}
.insta-link{
  align-self: flex-end;
}

.spacer{
  padding: 0px 2rem;
}
.contact-form button{
  margin-top: 1rem;
}
.insta-heading{
  margin-top: 2rem;
}
form.contact-form span{
  display: block;
  margin: 5px 0px;

}
#email, #subject, #message{
  width: 100%;
}
#message{
  min-height:20rem;
}
.about-edit > textarea {
  width: 100%;

}

.admin > h2 {
  margin-top: 2ssem;
}

#edit-button, #delete-button{
  margin: 0px 5px;
}


.card-body, .card-footer{
  background: #F4F2F8 !important;
}

hr{
    margin: .5rem 0px !important;
}
.navigation{
  border-top: #300D20 solid 5px;
  border-bottom: #300D20 solid 5px;
  background-color: #D1C8E1;
}

body{
  background-color: #c3c3e6 !important;
}

h1  {
color: #300D20;
}



h2, h3{
  color: #300d20
}

.about > p{
}

.about > h2 {
  font-family: 'Cinzel', serif;
}

.nav-link{
  color: #422C4E;
  font-family: 'Cinzel', serif;
}

.heading{
  text-align: center;
  font-family: 'Cinzel', serif;
}

.about > h1, .contact > h1{
  font-size: 17pt;
  display: inline-block;
}

.about > img{
  float: right;
  margin: 1rem;
}

.about, .contact{
  margin-top: 2rem;
}

main{
    min-height: 89vh;
}

.insta-icon > img{
height: 2ch;
}

Button{
    margin: 2px;
}

ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

li {
  height: 42vh;
  flex-grow: 1;
  margin: 5px;
}

li:last-child {
  flex-grow: 10;
}

.gallery-img {
  max-height: calc(100% - 1.5em);
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  /* max-width:300px; */
}

.gallery-img-nfs{

  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  /* max-width:300px; */
}

.gallery-box{
  background-color: aliceblue;
  text-align: right;
}
.gallery-text-container{
  display: flex;
  justify-content: space-between;
  padding: 0 .3em;
}
.gallery-text-price{
  font-style: italic;
  align-self: flex-end;
}
.gallery-text-label{
  font-style: italic;
}


.footer-lightbox{
    background-color: white;
    text-align: center;
    height: 5rem;
    display: flex;
    flex-wrap: wrap; 
}

.footer-lightbox > div{
    text-align: left;
    width: fit-content;
    margin: auto;
}

.footer-lightbox > div > p{
 margin: 0px;
}

.head-shot{
  float: left;
}

.gallery-heading::after{
  content:"  -  Click on the Picture for More Information";
  font-size: 12pt;
  vertical-align: middle;

}



@media (max-aspect-ratio: 1/1) {
  li {
    height: 30vh;
  }
}


@media (max-height: 480px) {
  li {
    height: 80vh;
  }
}


@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  ul {
    flex-direction: row;
  }

  li {
    height: auto;
    width: 100%;
  }
  li > img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
  .about > img {
    float: none;
  }
}